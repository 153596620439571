import { Container } from "react-bootstrap";
import PortfolioItem from "./PortfolioItem";

function Portfolio() {
    return (
        <Container className="d-flex flex-column gap-5 pb-4">
            <h1 className="display-5 text-center pt-5">Portfolio / Personal Projects</h1>
            <PortfolioItem
                images={[
                    "project6 (1).png",
                    "project6 (2).png",
                    "project6 (3).png",
                    "project6 (4).png",
                    "project6 (5).png",
                    "project6 (6).png",
                    "project6 (7).png",
                    "project6 (8).png",
                    "project6 (9).png",
                    "project6 (10).png",
                    "project6 (11).png",
                    "project6 (12).png",
                    "project6 (13).png",
                    "project6 (14).png",
                    "project6 (15).png",
                ]}
                title="Crop Yield Prediction"
                text={
                    <div>
                        <div>
                            A web application capable of predicting the production of a crop based
                            on information about its environment. Deep Learning models, based on
                            agricultural data collected from the Ethiopian Statistics Service, are
                            trained and used to perform the prediction.
                        </div>
                        <div className="mt-3">
                            • Used: Python, C#, JavaScript, Google Colaboratory, Flask, Keras,
                            Scikit-learn, ASP.NET Core, React, SQL Server, Git, GitHub, Entity
                            Framework Core, PyCharm, VS Code, VS 2022, Postman, Swagger
                        </div>
                    </div>
                }
            />
            <PortfolioItem
                images={[
                    "project4 (1).png",
                    "project4 (2).png",
                    "project4 (3).png",
                    "project4 (4).png",
                    "project4 (5).png",
                ]}
                title="Store Inventory Management"
                text={
                    <div>
                        <div>Focus: Socket Programming </div>
                        <div>Used: Java, JavaFX, MySQL, IntelliJ IDEA 2019</div>
                    </div>
                }
            />
            <PortfolioItem
                images={[
                    "project1 (1).png",
                    "project1 (2).png",
                    "project1 (3).png",
                    "project1 (4).png",
                    "project1 (5).png",
                ]}
                title="Import Tax Duty Payment"
                text={
                    <div>
                        <div>Focus: System Componentization </div>
                        <div>Used: JavaScript, React, Node.js, MySQL, VS Code Postman</div>
                    </div>
                }
            />

            <PortfolioItem
                images={[
                    "project3 (1).png",
                    "project3 (2).png",
                    "project3 (3).png",
                    "project3 (4).png",
                    "project3 (6).png",
                ]}
                title="House Rental Advertisement"
                text={
                    <div>
                        <div>Focus: RMI (Remote Method Invocation) in a 3-tier architecture </div>
                        <div>Used: Java, Scene Builder, MySQL, Git, GitHub, IntelliJ IDEA 2019</div>
                    </div>
                }
            />

            <PortfolioItem
                images={[
                    "project5 (7).png",
                    "project5 (2).png",
                    "project5 (3).png",
                    "project5 (4).png",
                    "project5 (5).png",
                ]}
                title="Online Store"
                text={
                    <div>
                        <div>Focus: Web Design </div>
                        <div>Used: PHP, HTML, CSS, JavaScript, MySQL, Xampp, VS Code</div>
                    </div>
                }
            />
            <PortfolioItem
                images={[
                    "project2 (3).png",
                    "project2 (4).png",
                    "project2 (5).png",
                    "project2 (6).png",
                    "project2 (7).png",
                ]}
                title="Employee / HR Management "
                text={
                    <div>
                        <div>Focus: Real World Project for AASTU </div>
                        <div>Used: C#, SQL Server, VS 2018</div>
                    </div>
                }
            />
        </Container>
    );
}

export default Portfolio;
