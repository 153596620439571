import React from "react";
import { Container, Row, Col, Image } from "react-bootstrap";
import { motion } from "framer-motion";
import { Link } from "react-router-dom";
import CodingImage from "../../images/arif-riyanto-vJP-wZ6hGBg-unsplash.jpg";
import { headerData } from "../../data/headerData";

function AboutMe() {
    return (
        <Container className="py-5">
            <Row>
                <Col className="d-flex justify-content-center align-items-center">
                    <div>
                        <motion.h1
                            className="display-6 text-center"
                            initial={{ y: 0 }}
                            whileInView={{ y: 0 }}
                            transition={{ type: "spring" }}
                        >
                            About Me
                        </motion.h1>
                        <motion.p
                            className="m-5"
                            initial={{ y: 0 }}
                            whileInView={{ y: 0 }}
                            transition={{ delay: 0.1, type: "spring" }}
                        >
                            {headerData.desciption}
                        </motion.p>
                        <Link
                            className="d-flex justify-content-center mb-5 text-decoration-none"
                            to="/portfolio"
                        >
                            <motion.a
                                as={Link}
                                className="btn btn-outline-dark btn-lg"
                                whileHover={{ scale: 1.2 }}
                                initial={{ y: 0 }}
                                whileInView={{ y: 0 }}
                                transition={{ type: "spring" }}
                            >
                                My Porfolio
                            </motion.a>
                        </Link>
                    </div>
                </Col>
                <Col className="d-flex justify-content-center" sm={12} lg={6}>
                    <div>
                        <Image className="px-5 ratio ratio-4x3" src={CodingImage} fluid rounded />
                    </div>
                </Col>
            </Row>
        </Container>
    );
}

export default AboutMe;
