export const socialsData = {
  github: "https://github.com/natty-nigatu",
  facebook: "",
  linkedIn: "https://www.linkedin.com/in/nathanael-argaw ",
  instagram: "https://www.instagram.com/natty.nigatu",
  codepen: "",
  twitter: "",
  reddit: "",
  blogger: "",
  medium: "",
  stackOverflow: "",
  gitlab: "",
  youtube: "",
};
