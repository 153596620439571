import { Card } from "react-bootstrap";
import { Link } from "react-router-dom";
import ImageF1 from "../../images/Hallucinations.png";
import { motion } from "framer-motion";
import PropTypes from "prop-types";

function PostCard({ viewImage }) {
  return (
    <motion.div
      className="card border-0 shadow rounded"
      whileHover={{ scale: 1.1 }}
      initial={{ y: 100 }}
      animate={{ y: 0 }}
      transition={{
        type: "spring",
        y: { delay: Math.random() / 2, stiffness: 200 },
      }}
    >
      {viewImage && (
        <Card.Img variant="top" src={ImageF1} className="img-fluid" />
      )}
      <Card.Body>
        <Card.Title>AI Hallucinations </Card.Title>
        <Card.Text>
          AI hallucinations are mistakes or incorrect results produced by AI
          systems. Read more about it here.
        </Card.Text>
        <div className="d-grid">
          <a
            href="https://medium.com/@nattynigatu28/ai-hallucinations-614844005775"
            target="_blank"
            className="btn btn-outline-dark stretched-link"
          >
            Read More
          </a>
        </div>
      </Card.Body>
    </motion.div>
  );
}

PostCard.defaultProps = {
  viewImage: true,
};

PostCard.propTypes = {
  viewImage: PropTypes.bool,
};

export default PostCard;
