import React from "react";
import { Row, Col, Container } from "react-bootstrap";
import PostCard from "./post/PostCard";

function Blogs() {
  return (
    <Container className="pt-5 mb-5 pb-5">
      <h1 className="text-center mb-4">Blog Posts</h1>
      <div className="px-5 mb-5 text-center">
        <h4>
          Welcome to my blog! Here you will find all of my latest posts on
          various topics. Feel free to read through them and reach out if you
          want me to cover other topics.
        </h4>
      </div>
      <Row
        className="g-5"
        xs={1}
        md={2}
        lg={3}
        data-masonry='{"percentPosition": true }'
      >
        <Col>
          <PostCard />
        </Col>
      </Row>
    </Container>
  );
}

export default Blogs;
