import { Row, Col, Carousel, Image } from "react-bootstrap";

function PortfolioItem({ images, title, text }) {
    return (
        <Row xs={1} md={2} style={{ minHeight: "25rem" }}>
            <Col className="d-flex justify-content-center align-items-center">
                <Carousel className="carousel-dark">
                    {images.map((image) => (
                        <Carousel.Item>
                            <Image
                                className="d-block w-100"
                                src={`./images/${image}`}
                                alt="First slide"
                            />
                        </Carousel.Item>
                    ))}
                </Carousel>
            </Col>
            <Col className="d-flex justify-content-center align-items-center">
                <div className="d-inline-block pe-5" style={{ maxWidth: "30rem" }}>
                    <h1 className="display-6 text-center mb-5">{title}</h1>
                    <p>{text}</p>
                </div>
            </Col>
        </Row>
    );
}

export default PortfolioItem;
