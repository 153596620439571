import { Row, Col, Image } from "react-bootstrap";

function SkillItem({ icon, title, text }) {
    return (
        <Row xs={1} md={2} style={{ minHeight: "25rem" }}>
            <Col className="d-flex justify-content-center align-items-center">
                <div className="d-inline-block" style={{ maxWidth: "25rem" }}>
                    <Image fluid src={icon} />
                </div>
            </Col>
            <Col className="d-flex justify-content-center align-items-center">
                <div className="d-inline-block pe-5" style={{ maxWidth: "30rem" }}>
                    <h1 className="display-6 text-center mb-3">{title}</h1>
                    <p>{text}</p>
                </div>
            </Col>
        </Row>
    );
}

export default SkillItem;
