import React, { useState } from "react";
import {
  Container,
  Row,
  Col,
  Form,
  Image,
  Button,
  Card,
} from "react-bootstrap";
import PaperPlaneImage from "../../images/daria-nepriakhina-guiQYiRxkZY-unsplash.jpg";
import { CircularProgress, Grid } from "@material-ui/core";
import axios from "axios";

const emptyForm = () => ({
  from_name: "",
  reply_to: "",
  message_title: "",
  phone: "",
  message: "",
});

function ContactMe() {
  const [form, setForm] = useState(emptyForm);
  const [submitted, setSubmitted] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const handleChange = (e) => {
    setForm({ ...form, [e.target.name]: e.target.value });
  };

  const showMessage = (message) => {
    setIsLoading(false);
    setSubmitted(message);
    setTimeout(() => {
      setSubmitted("");
    }, 7000);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setIsLoading(true);

    axios({
      method: "post",
      url: "https://jpeg021pul.execute-api.us-east-1.amazonaws.com/v1/contact",
      data: form,
    })
      .then((res) => {
        setForm(emptyForm());
        showMessage("Message Sent Successfully. I'll Get Back To You ASAP.");
      })
      .catch((err) => {
        setForm(emptyForm());
        showMessage("Error Sending Message. Please Try Again Later.");
      });
  };

  return (
    <Container>
      <Row className="px-5 mb-5" style={{ marginTop: "5rem" }}>
        <Col className="d-none d-lg-flex justify-content-center">
          <div className="d-flex">
            <Image
              className="px-5 my-auto"
              src={PaperPlaneImage}
              fluid
              rounded
            />
          </div>
        </Col>
        <Col xs={12} lg={8}>
          <h1 className="display-6">Contact Me</h1>
          <h3 className="mb-4">Email me at nathanaelargaw@gmail.com</h3>
          <h4 className="mb-4">Send Me A Message and I'll Get Back To You.</h4>
          <Form onSubmit={handleSubmit}>
            <Form.Group className="mb-2">
              <Form.Label>Name</Form.Label>
              <Form.Control
                required
                type="text"
                placeholder="Enter name"
                name="from_name"
                value={form.from_name}
                onChange={handleChange}
              />
            </Form.Group>

            <Grid container spacing={2}>
              <Grid item xs={12} lg={6}>
                <Form.Group className="mb-2">
                  <Form.Label>Email address</Form.Label>
                  <Form.Control
                    required
                    type="email"
                    placeholder="Enter email"
                    name="reply_to"
                    value={form.reply_to}
                    onChange={handleChange}
                  />
                </Form.Group>
              </Grid>
              <Grid item xs={12} lg={6}>
                <Form.Group className="mb-2">
                  <Form.Label>Phone Number</Form.Label>
                  <Form.Control
                    type="tel"
                    pattern="([0-9]{3}-[0-9]{3}-[0-9]{4})|([0-9]{10})"
                    title="123-456-7890 or 1234567890"
                    placeholder="Enter phone number"
                    name="phone"
                    value={form.phone}
                    onChange={handleChange}
                  />
                </Form.Group>
              </Grid>
            </Grid>

            <Form.Group className="mb-2">
              <Form.Label>Title</Form.Label>
              <Form.Control
                required
                type="text"
                placeholder="Enter message title"
                name="message_title"
                value={form.message_title}
                onChange={handleChange}
              />
            </Form.Group>

            <Form.Group className="mb-2">
              <Form.Label>Message</Form.Label>
              <Form.Control
                required
                as="textarea"
                rows={3}
                placeholder="Message"
                name="message"
                value={form.message}
                onChange={handleChange}
              />
            </Form.Group>
            {submitted ? (
              <h4 className="text-center">{submitted}</h4>
            ) : (
              <div className="d-grid">
                <Button variant="outline-dark" type="submit">
                  {isLoading ? (
                    <span>
                      <CircularProgress size={14} /> Sending . . .{" "}
                    </span>
                  ) : (
                    "Submit"
                  )}
                </Button>
              </div>
            )}
          </Form>
        </Col>
      </Row>
    </Container>
  );
}

export default ContactMe;
