import React from "react";
import { Container } from "react-bootstrap";
import ContactMeMain from "./home/ContactMe";

function ContactMe() {
  return (
    <Container mt={8}>
      <ContactMeMain />
      <div className="text-center display-6 mb-3 fs-2">
        If You Want To Find Me Anywhere Else On The Internet
      </div>
    </Container>
  );
}

export default ContactMe;
