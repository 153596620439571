import Particles from "./fragments/Particles";
import Typed from "react-typed";
import LaptopImage from "../images/christopher-gower-m_HRfLhgABo-unsplash.jpg";
import AboutMe from "./home/AboutMe";
import ContactMe from "./home/ContactMe";
import Expertise from "./home/Expertise";
import SkillsScroll from "./Skills/Skills";
import Testimonials from "./home/Testimonials";
import Landing from "./Landing/Landing";
import Education from "./Education/Education";
import Projects from "./Projects/Projects";
import Achievement from "./Achievement/Achievement";
import Experience from "./Experience/Experience";
import BackToTop from "./BackToTop/BackToTop";

function Home() {
  return (
    <>
      <div
        className="img-background"
        style={{
          backgroundImage: `url(${LaptopImage})`,
        }}
      >
        <Particles />
        <BackToTop />
        <div
          className=" d-flex align-items-center justify-content-center"
          style={{ height: "100vh" }}
        >
          <div>
            <h1 className="text-light text-center home-text ">Hi, I'm Natty</h1>
            <h1 className="text-light text-center display-5 mb-3">
              A Software Engineer
            </h1>

            <Typed
              className="fs-1 code text-light"
              strings={["Based in Maryland, USA", "Nice To Meet You! :)"]}
              typeSpeed={90}
              backSpeed={60}
              loop
            />
          </div>
        </div>
      </div>

      <div>
        <Landing />
        <Experience />
        <Education />
        <Expertise />
        <SkillsScroll />
        <ContactMe />
      </div>
    </>
  );
}

export default Home;
