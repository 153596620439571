import resume from "../assets/pdf/resume.pdf";

export const headerData = {
  name: "Nathanael Argaw",
  title: "Software Engineer",
  desciption: (
    <div>
      Accomplished and adaptive software engineer with over 5 years of
      experience in end-to-end software development life cycle working in agile
      and iterative environments. Expertise in developing client-focused
      web-based ERP systems using .NET Core, Angular, React, and Azure. Skilled
      in system design and analysis of applications, web service architectures,
      and Restful APIs. Dedicated to continuous learning to provide innovative
      solutions for challenging problems.
      <div className="mt-2">
        If you also want to collaborate on something intersting I'm very excited
        to hear from you. We're here for a very short time, let's make it count!
      </div>
      <div className="mt-2">Please reach out at nathanaelargaw@gmail.com</div>
    </div>
  ),
  image: "./images/myimage.jpg",
  resumePdf: resume,
};
