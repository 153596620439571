export const experienceData = [
  {
    id: 1,
    company: "GEICO, Chevy Chase, MD, USA",
    jobtitle: "Engineer II",
    startYear: "Jan 2024",
    endYear: "Current",
  },
  {
    id: 2,
    company: "Meselal Technologies, Addis Ababa, Ethiopia",
    jobtitle: "Senior Software Engineer",
    startYear: "June 2021",
    endYear: "May 2023",
  },
  {
    id: 3,
    company: "7G Star (7Glory)  , Addis Ababa, Ethiopia",
    jobtitle: "Full Stack Developer",
    startYear: "February 2018",
    endYear: "June 2021",
  },
];
