import { useEffect } from "react";
import { Container, Navbar, Nav } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import { motion } from "framer-motion";
import { useAuth } from "../../contexts/AuthContext";

function Header() {
  const auth = useAuth();
  const navigate = useNavigate();

  useEffect(() => {
    let loggedInUser;
    if (Object.keys(auth.get).length === 0) {
      try {
        const loggedString = localStorage.getItem("loggedInUser");
        if (loggedString.trim().length === 0) return;
        loggedInUser = JSON.parse(loggedString);
      } catch {
        return;
      }

      auth.set((prev) => loggedInUser);
    }
  }, []);

  return (
    <header style={{ marginBottom: "3rem" }}>
      <Navbar
        bg="light"
        expand="sm"
        className="py-3 shadow-sm border-bottom"
        fixed="top"
      >
        <Container>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="m-auto gap-5">
              <motion.span
                whileHover={{ scale: 1.2 }}
                initial={{ scale: 0.7 }}
                animate={{ scale: 1 }}
                transition={{ type: "spring" }}
              >
                <Nav.Link as={Link} to="/">
                  Home
                </Nav.Link>
              </motion.span>
              {/*
                            <motion.span
                                whileHover={{ scale: 1.2 }}
                                initial={{ scale: 0.7 }}
                                animate={{ scale: 1 }}
                                transition={{ type: "spring" }}
                            >
                                <Nav.Link as={Link} to="/resume">
                                    Resume
                                </Nav.Link>
                            </motion.span>
    */}

              <motion.span
                whileHover={{ scale: 1.2 }}
                initial={{ scale: 0.7 }}
                animate={{ scale: 1 }}
                transition={{ type: "spring" }}
              >
                <Nav.Link as={Link} to="/portfolio">
                  Portfolio
                </Nav.Link>
              </motion.span>

              <motion.span
                whileHover={{ scale: 1.2 }}
                initial={{ scale: 0.7 }}
                animate={{ scale: 1 }}
                transition={{ type: "spring" }}
              >
                <Nav.Link as={Link} to="/about">
                  About Me
                </Nav.Link>
              </motion.span>
              <motion.span
                whileHover={{ scale: 1.2 }}
                initial={{ scale: 0.7 }}
                animate={{ scale: 1 }}
                transition={{ type: "spring" }}
              >
                <Nav.Link as={Link} to="/post">
                  Blog Posts
                </Nav.Link>
              </motion.span>
              <motion.span
                whileHover={{ scale: 1.2 }}
                initial={{ scale: 0.7 }}
                animate={{ scale: 1 }}
                transition={{ type: "spring" }}
              >
                <Nav.Link as={Link} to="/contact">
                  Contact Me
                </Nav.Link>
              </motion.span>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </header>
  );
}

export default Header;
