import "./App.css";
import Header from "./components/layouts/Header";
import "bootstrap/dist/css/bootstrap.min.css";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Home from "./components/Home";
import Footer from "./components/layouts/Footer";
import Posts from "./components/Posts";
import ContactMe from "./components/ContactMe";
import Portfolio from "./components/home/Portfolio";
import ContextProvider from "./contexts/ContextProviders";
import AboutMe from "./components/home/AboutMe";

function App() {
  return (
    <ContextProvider>
      <Router>
        <Header />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/post" element={<Posts />} />
          <Route path="/contact" element={<ContactMe />} />
          <Route path="/portfolio" element={<Portfolio />} />
          <Route path="/about" element={<AboutMe />} />
        </Routes>
        <Footer />
      </Router>
    </ContextProvider>
  );
}

export default App;
