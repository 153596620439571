import TestimonialItem from "./TestimonialItem";
function Testimonials() {
    return (
        <>
            <h1 className="display-6 text-center my-5">
                Testimonials From People I've Worked With
            </h1>

            <div className="d-flex overflow-auto gap-5 pb-5 px-5">
                {[
                    "primary",
                    "secondary",
                    "success",
                    "danger",
                    "warning",
                    "info",
                    "light",
                    "dark",
                ].map((variant) => (
                    <TestimonialItem
                        variant={variant}
                        name={"Collegue Name"}
                        text="Lorem ipsum dolor sit amet consectetur adipisicing elit. Soluta
                   laudantium ut sint dolor nostrum nam odio facere voluptas, atque
                   rerum nesciunt sed odit."
                    />
                ))}
            </div>
        </>
    );
}

export default Testimonials;
