export const educationData = [
  {
    id: 1,
    institution: "Maharishi International University",
    course: "Computer Science, MSc",
    startYear: "2023",
    endYear: "Current (Online)",
  },
  {
    id: 2,
    institution: "Addis Ababa Science and Technology University",
    course: "Software Engineering, BSc",
    startYear: "2018",
    endYear: "2022",
  },
];
