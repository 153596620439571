/* eslint-disable */
import {
    greenThemeLight,
    greenThemeDark,
    bwThemeLight,
    bwThemeDark,
    blueThemeLight,
    blueThemeDark,
    redThemeLight,
    redThemeDark,
    orangeThemeLight,
    orangeThemeDark,
    purpleThemeLight,
    purpleThemeDark,
    pinkThemeLight,
    pinkThemeDark,
    yellowThemeLight,
    yellowThemeDark,
} from "../theme/theme";

export const themeData = {
    theme: bwThemeLight,
};

// Choose theme from above
