import React from "react";
import { Row, Col, Container } from "react-bootstrap";
import { FaInstagram, FaGithub, FaLinkedin } from "react-icons/fa";
import { Link } from "react-router-dom";

function Footer() {
  return (
    <>
      <div className="mx-5 px-5 mb-4 display-6 fs-2 d-flex justify-content-center gap-4">
        <span>Let's Be Friends</span>
        <a
          href="https://www.linkedin.com/in/nathanael-argaw "
          target="_blank"
          style={{ color: "black" }}
        >
          <FaLinkedin />
        </a>
        <a
          href="https://www.github.com/natty-nigatu"
          target="_blank"
          style={{ color: "black" }}
        >
          <FaGithub />
        </a>
        <a
          href="https://www.instagram.com/natty.nigatu"
          target="_blank"
          style={{ color: "black" }}
        >
          <FaInstagram />
        </a>
      </div>
      {/*
            <Container fluid className="footer-style ps-5 py-5">
                <Row>
                    <Col className="d-flex flex-column gap-2">
                        <strong>Shortcuts</strong>
                        <Link to="/" className="text-decoration-none text-dark">
                            Home
                        </Link>
                        <Link to="/#about" className="text-decoration-none text-dark">
                            About Me
                        </Link>
                        <Link to="/" className="text-decoration-none text-dark">
                            Education
                        </Link>
                        <Link to="/" className="text-decoration-none text-dark">
                            Expertise
                        </Link>
                        <Link to="/" className="text-decoration-none text-dark">
                            Skills
                        </Link>
                        <Link to="/" className="text-decoration-none text-dark">
                            Testimonials
                        </Link>
                        <Link to="/" className="text-decoration-none text-dark">
                            Experience
                        </Link>
                        <Link to="/" className="text-decoration-none text-dark">
                            Achievements
                        </Link>
                        <Link to="/" className="text-decoration-none text-dark">
                            Portfolio
                        </Link>
                        <Link to="/" className="text-decoration-none text-dark">
                            Blog
                        </Link>
                    </Col>
                    <Col className="d-flex flex-column gap-2">
                        <strong>Posts</strong>
                        <Link to="#" className="text-decoration-none text-dark">
                            Post Title 1
                        </Link>
                        <Link to="#" className="text-decoration-none text-dark">
                            Post Title 1
                        </Link>
                        <Link to="#" className="text-decoration-none text-dark">
                            Post Title 1
                        </Link>
                    </Col>
                    <Col className="d-flex flex-column gap-2">
                        <strong> My Favorite Libraries</strong>
                        <Link to="#" className="text-decoration-none text-dark">
                            Library Title 1
                        </Link>
                        <Link to="#" className="text-decoration-none text-dark">
                            Library Title 1
                        </Link>
                        <Link to="#" className="text-decoration-none text-dark">
                            Library Title 1
                        </Link>
                        <Link to="#" className="text-decoration-none text-dark">
                            Library Title 1
                        </Link>
                        <Link to="#" className="text-decoration-none text-dark">
                            Library Title 1
                        </Link>
                        <Link to="#" className="text-decoration-none text-dark">
                            Library Title 1
                        </Link>
                    </Col>
                    <Col className="d-flex flex-column gap-2">
                        <strong> People I Follow</strong>
                        <Link to="#" className="text-decoration-none text-dark">
                            Person 1
                        </Link>
                        <Link to="#" className="text-decoration-none text-dark">
                            Person 1
                        </Link>
                        <Link to="#" className="text-decoration-none text-dark">
                            Person 1
                        </Link>
                    </Col>
                </Row>
            </Container>
            */}
      <div className="bg-dark py-4">
        <div className="text-center text-light">Nathanael Argaw, 2024.</div>
      </div>
    </>
  );
}

export default Footer;
